@import "~@blueprintjs/core/lib/scss/variables";

$custom-primary1: #5e2e85;
$custom-primary2: #8f58bd;
$custom-primary3: #775494;

$pt-intent-primary: $custom-primary1;
$pt-intent-success: $green1;
$pt-intent-warning: $orange1;
$pt-intent-danger: $red1;

$pt-intent-text-colors: (
  "primary": $custom-primary1,
  "success": $green2,
  "warning": $orange2,
  "danger" : $red2,
) !default;

$pt-dark-intent-text-colors: (
  "primary": $white,
  "success": $green5,
  "warning": $orange5,
  "danger" : $red5,
) !default;

$pt-icon-color: $custom-primary1;

// "intent": (default, hover, active colors)
$button-intents: (
  "primary": ($pt-intent-primary, $custom-primary2, $custom-primary3),
  "success": ($pt-intent-success, $green2, $green1),
  "warning": ($pt-intent-warning, $orange2, $orange1),
  "danger": ($pt-intent-danger, $red2, $red1)
)!default;

@import "~@blueprintjs/core/src/blueprint.scss";
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.App {
  text-align: center;
}

.App-dark {
  background-color: #2b3642;
  height: 100vh;
}

.App-page {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-title {
  color: $pt-intent-primary;
}

.App-title-dark {
  color: $white;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
