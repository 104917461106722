.docs-container {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 100%;
    grid-column-gap: 10px;
    max-width: 960px;
    margin: auto;
    padding-top: 50px;
  }
  
  .docs-left-col {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    justify-content: start;
    align-content: flex-start;
    padding: 10px;
  }
  
  .docs-right-col {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    align-self: center;
    padding: 10px;
  }
  
  .docs-steps-container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 100%;
    grid-column-gap: 5px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .docs-menu-item{
    margin-top: 4px;
    text-decoration: underline;
  }
  
  .docs-step1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    align-self: start;
    padding: 5px;
    text-align: left;
  }
  
  .docs-step2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    align-self: start;
    padding: 5px;
    text-align: left;
  }
  
  .docs-step3 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
    align-self: start;
    padding: 5px;
    text-align: left;
  }
  
  .docs-step4 {
    grid-column-start: 4;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
    align-self: start;
    padding: 5px;
    text-align: left;
  }
  
  .step-card {
    min-height: 110px;
    max-height: 500px;
  }
  
  .step-card-height {
    min-height: 320px;
  }
  
  .docs-button{
    color: white;
    font-style: oblique;
    font-weight: bold;
    background-color: #43bd16;
    border-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-width: 0rem;
  }
  
  .docs-button:hover{
    cursor: pointer;
  }
  
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
  
    .docs-container{
      grid-template-columns: 100%;
      grid-template-rows: 50% 50%;
    }
  
    .docs-left-col {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
      align-self: center;
      padding: 50px;
    }
    
    .docs-right-col {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;
      align-self: center;
      padding: 50px;
    }
  
    .docs-steps-container {
      grid-template-rows: 25% 25% 25% 25%;
      grid-template-columns: 100%;
    }
    
    .docs-step1 {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 1;
      grid-row-end: 1;
      align-self: start;
      padding: 5px;
      text-align: left;
    }
    
    .docs-step2 {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 2;
      grid-row-end: 2;
      align-self: start;
      padding: 5px;
      text-align: left;
    }
    
    .docs-step3 {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 3;
      grid-row-end: 3;
      align-self: start;
      padding: 5px;
      text-align: left;
    }
    
    .docs-step4 {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 4;
      grid-row-end: 4;
      align-self: start;
      padding: 5px;
      text-align: left;
    }
  
    .step-card {
      min-height: 450px;
    }
  
    .step-card-height {
      min-height: 250px;
    }
  }